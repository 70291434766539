import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      requireAuth: false
    },
    redirect:'/homepage',
  },
  {
    path: '/',
    component: resolve => require(['../components/inAll.vue'], resolve),
    meta:{
      requireAuth: true,
    },
    children: [
      {
        path: '/homepage',
        alias: 'homepage',
        component: resolve => require(['../views/home.vue'], resolve),
        name: 'homepage'
      },
      {
        path: '/comprehensive',
        component: resolve => require(['../views/comprehensive.vue'], resolve),
        name: 'comprehensive'
      },
      {
        path: '/service',
        component: resolve => require(['../views/services.vue'], resolve),
        name: 'service'
      },
      {
        path: '/about',
        component: resolve => require(['../views/about.vue'], resolve),
        name: 'about'
      },
      {
        path: '/apply',
        component: resolve => require(['../views/apply.vue'], resolve),
        name: 'apply'
      }  
    ]
  },
  {
    path: '/onenews',
    name: 'onenews',
    component: () => import(/* webpackChunkName: "about" */ '../components/news.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

router.afterEach(() => {
  window.scrollTo(0,0);
})

//import Vue from 'vue'
//import App from './App.vue'
//import router from './router'
//入口文件
import Vue from 'vue'

import App from './App.vue'

// import '../static/style/_globalcolor.scss'

//导入自己的路由模块
import router from './router/index' 

//导入路由模块,安装路由
import VueRouter from 'vue-router'
Vue.use(VueRouter)


//引入工具函数
import myutils from './utils/index';
Vue.prototype.$myutils = myutils; 

// 注册element-ui
import ELEMENT from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'
Vue.use(ELEMENT,{size:'small',zIndex:3000});

// 引入axios
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_IP;
axios.defaults.headers['Authorization'] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJzYWZlIiwiaWF0IjoxNzAyNDQ5MjU0LCJpc3MiOiJzYWZlIiwidmVyaWZ5X2lkIjoiZjBmZjNlNDQ0NjcxNDgwYmJlODNmYzljYzk3MzdkZDMifQ.I5zLdkiyiYFIEh6lqaaYe9BKuGWLlKvlHcllgo2_qME';
// axios.defaults.withCredentials = true
Vue.prototype.$http = axios

import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
// AOS.init({
//   offset:100,
//   duration: 600,
//   easing: 'ease-in-sine',
// })
Vue.prototype.$aos = AOS;
// Vue.use(AOS);

//ie编译
//import 'babel-polyfill';
//import './element-var.scss'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// axios.interceptors.request.use(request => {
//   let UNSER_INFO = JSON.parse(localStorage.getItem("saleInfo"));
//   if(UNSER_INFO){
//     request.headers['Authorization']= UNSER_INFO.token;
//   }
//   return request;
// },error => {
//   return Promise.reject(error)
// });

// axios.interceptors.response.use(
//   res => {
//     if(res.data.code == 401){
//       //localStorage.removeItem('cInfo')
//       router.replace({
//         path: '/login',
//         query:{redirect: router.currentRoute.fullPath}
//       })
//     }
//     return res;
//   }, error => {
//     if(error.response){
//       switch(error.response.status){
//         case 401:
//         localStorage.removeItem('cInfo')
//         router.replace({
//           path: '/login',
//           query:{redirect: router.currentRoute.fullPath}
//         })
//       }
//     }
//     return Promise.reject(error)
//   }
// )

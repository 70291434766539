import router from '../router/index' 
function swichTime(timestamp){
    // 时间戳 
    // 此处时间戳以毫秒为单位
    let date = new Date(parseInt(timestamp) * 1000);
    let Year = date.getFullYear();
    let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    // let  GMT =  Year + '年' + Moth + '月' + Day + '日'+'   '+ Hour +':'+ Minute  + ':' + Sechond;
    let  GMT =  Year + '年' + Moth + '月' + Day + '日'
    
    return GMT  
};
function convertKey (arr, key) {
    let newArr = [];
    arr.forEach((item, index) => {
      let newObj = {};
      for (var i = 0; i < key.length; i++) {
        newObj[key[i]] = item[Object.keys(item)[i]]
      }
      newArr.push(newObj);
    })
    // console.log(newArr)
    return newArr;
}  
function jumpto(n){
  router.push({name:n})
}
// 表格样式方法
function tableRowStyle({ row, rowIndex }){
  return 'background-color:#000;'
};
function tableHeaderColor({ row, column, rowIndex, columnIndex }){
  return 'color:#8f9399;font-weight: 600;font-size:12px;background-color: transparent;'
};

export default {
  swichTime,
  convertKey,
  tableRowStyle,
  tableHeaderColor,
  jumpto
}